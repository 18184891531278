<template lang="pug">
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mb-5.lh-18.privacy
		h1.mb-6 {{ $t('ru_privacy.title') }}
		p.text-muted.mb-4 {{ $t('date.published') }}: {{ updatedDate }}

		p.mb-4(v-html="$t('ru_privacy.head')")

		hr.primary.my-6

		slot(v-for='(c,index) in common')
			h2.mb-6 {{index + 1}}. {{ c.title }}
			p(v-if='c.desc') {{ c.desc }}
			ol.ol-deep.ol-deep-parent.mb-4(:style="`--n: '${index+1}`")
				li(v-for='i in c.ul' :key='i.text' :data-counter="index + 1")
					div(v-if='i.ulDeep')
						span(v-html='i.text')
						ol.ol-deep.mt-2(:style="`--n: '${index+1}`" v-if='i.ulDeep')
							.vuetable(v-if='i.ulDeep[0].table')
								.table-responsive
									table.my-4.table.b-table.table-bordered
										thead
											tr
												td.f-1 {{ i.ulDeep[0].table.th[0] }}
												td.f-1 {{ i.ulDeep[0].table.th[1] }}
										tbody
											tr(v-for='tr in i.ulDeep[0].table.tr')
												td(v-for='td in tr.td' v-html='td')
							li(v-else v-for='d in i.ulDeep' :key='d.text' :data-counter="index + 1")
								div(v-html='d.text')
					span(v-else-if='i.title')
						b {{ i.title }} –&nbsp;
						span(v-html='i.text')
					span(v-else v-html='i.text')
			hr.primary.my-6

		h2.mb-6 12. {{ $t('finance.requisitesAndContacts') }}
		company-data
		br
		br
		pw-btn-up
		modal-cookie
</template>

<script>
import CompanyData from '@/components/Common/Company';
import PwBtnUp from '@/components/Buttons/PwBtnUp';
import ModalCookie from '@/components/Modals/Cookie';
import moment from 'moment';
import { bus } from '@/main';

export default {
    metaInfo() {
        return { title: bus.$t('ru_privacy.title') };
    },
    components: {
        CompanyData,
        ModalCookie,
        PwBtnUp,
    },
    data() {
        let date = '2022-06-21';
        let day = moment(date).format('DD');

        return {
            updatedDate: moment(date).format('DD MMMM YYYY').replace(day, `«${day}»`),
        };
    },
    computed: {
        common() {
            let data = _.cloneDeep(bus.$t('ru_privacy.common'));

            _.each(data, (m, i) => {
                _.each(data[i].ul, (u, iu) => {
                    data[i].ul[iu].text = data[i].ul[iu].text.replace(/\$\{bus\.botPayName\}/, bus.botPayName);
                    data[i].ul[iu].text = data[i].ul[iu].text.replace(/\$\{bus\.botSupportName\}/, bus.botSupportName);
                    data[i].ul[iu].text = data[i].ul[iu].text.replace(/\$\{bus\.botSupport\}/, bus.botSupport);

                    _.each(data[i].ul[iu].ulDeep, (d, id) => {
                        if (data[i].ul[iu].ulDeep[id].text) {
                            data[i].ul[iu].ulDeep[id].text = data[i].ul[iu].ulDeep[id].text.replace(
                                /\$\{bus\.botPayName\}/,
                                bus.botPayName
                            );
                            data[i].ul[iu].ulDeep[id].text = data[i].ul[iu].ulDeep[id].text.replace(
                                /\$\{bus\.botSupportName\}/,
                                bus.botSupportName
                            );
                            data[i].ul[iu].ulDeep[id].text = data[i].ul[iu].ulDeep[id].text.replace(
                                /\$\{bus\.botSupport\}/,
                                bus.botSupport
                            );
                        }
                    });
                });
            });

            return data;
        },
    },
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<style lang="scss" scoped>
.privacy {
    margin-top: 76px;

    @media (max-width: 767px) {
        margin-top: 20px;
    }

    .ol-deep {
        li:before {
            content: attr(data-counter) '.' counters(li, '.') ' ';
        }
    }
}
</style>
